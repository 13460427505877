body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgba(76, 132, 182, 0.05);
  text-align: center;
}

button {
  appearance: none;
  border: none;
  background: none;
  font-size: 32px;
  padding-left: 12px;
  padding-right: 12px;
  outline: none;
  border: 2px solid transparent;
  color: rgb(76, 132, 182);
  padding-bottom: 4px;
  cursor: pointer;
  background-color: rgba(76, 132, 182, 0.15);
  border-radius: 2px;
  transition: all 0.15s;
  width: fit-content;
  margin: auto;
}

button:hover, button:focus {
  border: 2px solid rgba(76, 132, 182, 0.4);
}

button:active {
  background-color: rgba(76, 132, 182, 0.2);
}

button:disabled {
  cursor: not-allowed;
  border: none;
  background-color: rgba(181, 181, 181, 0.15);
  color: rgb(209, 229, 247);
}

input {
  font-size: 32px;
  padding: 2px;
  text-align: center;
}

td {
  padding: 5px;
  min-width: 40px;
}

thead td {
  text-decoration: underline;
}