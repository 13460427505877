
.DicePick {
  display: inline-block;
  padding: 120px 25px 0;
  margin: -120px 0 25px;
  z-index: 1;
  position: relative;
  cursor: pointer;
}

.DicePickInput {
  cursor: pointer;
}

.EndTurn {
  margin-left: 10px;
}

.DiceBox {
  height: 150px;
  margin-left: -68px;
}

.Game {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.Logs {
  flex: 1;
  overflow-y: scroll;
  border-top: 1px solid rgba(76, 132, 182, 0.15);
  border-bottom: 1px solid rgba(76, 132, 182, 0.15);
  padding: 10px;
  margin-bottom: 10px;
  max-height: 20vh;
}

.TableContainer {
  overflow-x: scroll;
}

.Table {
  margin: 0 auto;
}

.LeftFloat {
  position: sticky;
  left: 0;
  background-color: white;
}

.RightFloat {
  position: sticky;
  right: 0;
  background-color: white;
}
